<template>
	<div class="h-full flex flex-col relative">
		<TopBar :showBack="true"/>
		<AppTitle title="Change Password"/>
		<div class="pt-8 overflow-y-scroll flex-grow">

			<alert
				:type="alert.type"
				:message="alert.message" 
				v-if="alert.show"
				@dismiss="alert.show=false"
			/>

      <form @submit.stop.prevent="submit">
				<div class="space-y-4">
					<input type="password" class="block w-full py-4 px-6 rounded-3xl border border-gray-100 placeholder-gray-400 text-gray-500 focus:ring-red-500 focus:border-red-500" placeholder="Current Password" ref="current_password" id="current_password" v-model.trim="form.current_password" autocomplete="password" :class="{'border-red-500': errors.current_password, 'focus:border-red-500': errors.current_password, 'focus:border-red': !errors.current_password}" @keydown="errors.current_password = false">
					<span class="text-xs text-red-500" v-if="errors.current_password">{{ errors.current_password[0] }}</span>
					<input type="password" class="block w-full py-4 px-6 rounded-3xl border border-gray-100 placeholder-gray-400 text-gray-500 focus:ring-red-500 focus:border-red-500" placeholder="New Password" v-model.trim="form.password" :class="{'border-red-500': errors.password, 'focus:border-red-500': errors.password, 'focus:border-red': !errors.password}" @keydown="errors.password = false">
					<span class="text-xs text-red-500" v-if="errors.password">{{ errors.password[0] }}</span>
					<input type="password" class="block w-full py-4 px-6 rounded-3xl border border-gray-100 placeholder-gray-400 text-gray-500 focus:ring-red-500 focus:border-red-500" placeholder="Confirm Password" v-model.trim="form.password_confirmation" :class="{'border-red-500': errors.password_confirmation, 'focus:border-red-500': errors.password_confirmation, 'focus:border-red': !errors.password_confirmation}" @keydown="errors.password_confirmation = false">
					<span class="text-xs text-red-500" v-if="errors.password_confirmation">{{ errors.password_confirmation[0] }}</span>
				</div>
				<button type="submit" :disabled="loading" class="mt-8 bg-red-500 text-white py-2 rounded-xl w-full font-gothic text-lg" :class="{'btn-red-500' : loading, 'hover:btn-secondary bg-red': !loading}">
					<svg v-if="loading" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
						<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
						<path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
					</svg> <span>Update Password</span>
				</button>
			</form>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Alert from '@/components/ui/Alert'
	import TopBar from '@/components/TopBar.vue';
	import AppTitle from '@/components/AppTitle.vue';
	export default {
		components: {
			TopBar,
			AppTitle,
			Alert,
		},
		data(){
			return {
				error_message: '',
				loading: false,
				form: {
					current_password: '',
					password: '',
					password_confirmation: '',
				},
				errors: {},
				alert: {
					type: '',
					message: '',
					show: false
				},
			}
		},
		methods: {
			submit(){
				this.$store.dispatch('profile/updatePassword', this.form)
				.then( () => {
					this.alert.type = "success";
					this.alert.message = "Password updated successfully";
					this.alert.show = true;
					this.form.current_password = "";
					this.form.password = "";
					this.form.password_confirmation = "";
					this.$refs.current_password.focus();
					this.errors = {};
				}).catch( e => {
					this.alert.type = "error";
					this.alert.message = "Error updating password";
					this.alert.show = true;
					if (e.response.data.message === "Current password is not valid."){
						this.errors.current_password = ["Current password is not valid."];
					} else {
						this.errors = e.response.data.errors;
					}
				});

			},
		}
	}
</script>
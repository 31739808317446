<template>
  <div class="flex">
    <div class="mt-2 text-4xl font-gothic text-black" v-text="title"></div>
    <div v-if="showMascot">
      <img src="/img/mascot-pizza.png" class="w-20">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    showMascot: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <div class="pb-4 text-center w-full">
    <p :class="`text-${color}-500`">
      {{ message }}
    </p>
  </div>
</template>

<script type="text/javascript">
  export default {
    props: {
      message: {
        type: String
      },
      type: {
        type: String
      },
    },
    mounted(){
      setTimeout( () => {
        this.$emit('dismiss');
      }, 5000);
    },
    computed: {
      color(){
        if (this.type === "error") return "red";
        if (this.type === "warning") return "yellow";
        return "green";
      }
    }
  }
</script>
<template>
	<div class="py-6 rounded-t-xl text-black text-lg grid grid-cols-6">
		<div class="col-span-1">
			<button @click="$router.go(-1)" v-if="showBack">
				<font-awesome-icon icon="fa-solid fa-chevron-left"/>
			</button>
			<button @click="$router.push({name: 'dashboard'})" v-if="showHome">
				<font-awesome-icon icon="fa-solid fa-chevron-left"/>
			</button>
			<button v-if="showMenu && !showBack" @click="showSidebar=true">
				<font-awesome-icon icon="fa-solid fa-bars" class="text-red-500"/>
			</button>
		</div>
		<div class="col-span-4 mx-auto h-7">
			<router-link :to="{ name: 'dashboard' }" v-if="!['welcome'].includes($route.name)">
				<img src="/img/hungryplus-horizontal-logo.png" class="h-6">
			</router-link>
			<img v-else src="/img/hungryplus-horizontal-logo.png" class="h-6">
		</div>
		<div class="col-span-1 text-right">
			<router-link v-if="showBell" :to="{ name: 'notifications' }" @click.native="animateBell=false">
				<font-awesome-icon 
					:icon="unreadNotificationsCount>0 ? 'fa-solid fa-bell' : 'far fa-bell'" 
					class="text-red-500"
					:class="{
						'fa-beat': this.animateBell
					}"
				/>
			</router-link>
			<button v-if="showClose" @click="dashboard">
				<font-awesome-icon icon="fa-solid fa-xmark"/>
			</button>
			<button v-if="showEllipsis" @click="dashboard">
				<font-awesome-icon icon="fa-solid fa-ellipsis-vertical"/>
			</button>
			<button v-if="showChat" @click="$router.push({ name: 'chat', params: { uuid: table.uuid } })">
				<font-awesome-icon icon="fa-regular fa-comment"/>
			</button>
			<button v-if="showLogout" @click="logout">
				<font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket"/>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		props: {
			showHome: {
				type: Boolean,
				default: false
			},
			showBack: {
				type: Boolean,
				default: false
			},
			showMenu: {
				type: Boolean,
				default: false
			},
			showBell: {
				type: Boolean,
				default: false
			},
			showClose: {
				type: Boolean,
				default: false
			},
			showEllipsis: {
				type: Boolean,
				default: false
			},
			showChat: {
				type: Boolean,
				default: false
			},
			showLogout: {
				type: Boolean,
				default: false
			}
		},
		data(){
			return {
				animateBell: false
			}
		},
		watch: {
			pusher(val){
				if (val){
					this.setupPusherListener();
				}
			}
		},
		mounted(){
			this.setupPusherListener();
		},
		methods: {
			setupPusherListener(){
				if (!this.user) return;
				if (this.pusher){
					this.channel = this.pusher.subscribe(`user-${this.user.id}`);
					this.channel.bind('get-notifications', () => {
						this.animateBell = true;
					});
				}
			},
			dashboard(){
				this.$router.push({name : 'dashboard'});
			},
			async logout(){
				await this.$store.dispatch('auth/logout')
				this.$router.push({ name: 'welcome' })
			}
		},
		computed: {
			user(){
				return this.$store.state.auth.user;
			},
			table(){
				return this.$store.state.table.table;
			},
			pusher(){
				return this.$store.state.pusher;
			},
			unreadNotificationsCount(){
				return this.$store.state.notifications.filter( x => x.read === 0).length;
			},
			showSidebar: {
				get(){
					return this.$store.state.showSidebar;
				},
				set(value){
					this.$store.commit('setSidebar', value);
				}
			},
		}
	}
</script>